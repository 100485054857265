
/* 1 Settings */

:root {
  --background-color: white;
  --inner-background-color: #f6f6f6;
  --line-color: #303645;
  --text-color: white;
  --activity-bar-width: 60px;
  --history-sidebar-width: 20%;
  --working-area-width: 60%;
  --main-area-width: calc(
    100% - var(--history-sidebar-width) - var(--token-sidebar-width)
  );
  --token-sidebar-width: 20%;
  --type-regular: "Roboto-Regular", -apple-system, blinkmacsystemfont,
    "Segoe UI", roboto, helvetica, arial, sans-serif;
  --type-bold: "Roboto-Medium", roboto, -apple-system, blinkmacsystemfont,
    "Segoe UI", helvetica, arial, sans-serif;
  --type-mono: "Roboto-Mono", monospace;
}

/* 1.1 Color scheme */

[data-color-scheme="light"] {
  --background-color: white;
  --inner-background-color: #f8f8f8;
  --line-color: #dbdbdb;
  --text-color: #111;
  --page-color: #f2f3f6;
  --well-color: white;
  --placeholder-color: #6b6b6b;
  --toggle-background-color: #cfcfcf;
  --tag-color: #ddd;
  --pretty-json-color: #666;
  --pretty-json-variable: #607a2a;
  --pretty-json-number: #AE81FF;
  --pretty-json-string: #a4971d;
  --pretty-json-selection-background-color: #e3e0c8;
}

[data-color-scheme="dark"] {
  --background-color: #1d2028;
  --inner-background-color: #2a2e3a;
  --line-color: #48516c;
  --text-color: white;
  --page-color: transparent;
  --well-color: #2b303c;
  --placeholder-color: #576184;
  --toggle-background-color: #10121a;
  --tag-color: #1f2331;
  --pretty-json-color: #F8F8F2;
  --pretty-json-variable: #A6E22E;
  --pretty-json-number: #AE81FF;
  --pretty-json-string: #E6DB74;
  --pretty-json-selection-background-color: #49483E;

}

.standalone[data-color-scheme="null"] {
  --text-color: #111;
}


/* 1.2 Buttons */
$button-line-height: 1.125rem !default;
$button-padding-y: 0.5rem !default;
$button-padding-x: 1rem !default;

/* -------------------------------------------------------------------
2 Colors
------------------------------------------------------------------- */

/* Main colors */
$primary: #626c87 !default;
$primary-light: lighten($primary, 15%) !default;
$primary-dark: darken($primary, 8%) !default;
$secondary: lighten($primary, 35%) !default;
$signicat: #2a0062;
$signicat-lighten: #f2f0f6;
$signicat-darken: #180247;
$tertiary: #f2eb80 !default;
$bright: #d859a1 !default;
$green: #27615c !default;

/* Authenticator colors */
$authenticator-github-color: #333;

/* Text color */
$color-paragraphs: lighten(black, 40%) !default;
$color-strong: $primary;

/* Greys */
$grey: #a9a9a9 !default;
$grey-dark: darken($grey, 30%) !default;
$grey-light-5: lighten($grey, 5%) !default;
$grey-light-10: lighten($grey, 10%) !default;
$grey-light-15: lighten($grey, 15%) !default;
$grey-light-20: lighten($grey, 20%) !default;
$grey-light-25: lighten($grey, 25%) !default;
$grey-light-30: lighten($grey, 30%) !default;

/* Call to action */
$cta-success: #57c75c !default;
$cta-info: #d9edf7 !default;
$cta-warning: #fcf8e3 !default;
$cta-danger: #a94442 !default;
$cta-add: #0092ff !default;

/* Syntax highlighting */
$key: #006fd1;
$value: #c7860d;
$string: #c7860d;
$number: #00ad64;
$disclosure: #01aac1;
$inlinecode: #cd3d64;

/* Received tokens */
$received_token1: #ffcb05;
$received_token2: #f76540;
$received_token3: #62cd9f;
$received_token4: #b67baa;
$received_token5: #b67cee;
$received_token6: #5ac45a;

/* Color classes */

.primary {
  color: $primary;
}

.secondary {
  color: $secondary;
}

.tertiary {
  color: $tertiary;
}

.bright {
  color: $bright;
}

.green {
  color: $green;
}

.warning {
  color: $cta-warning;
}

.info {
  color: $cta-info;
}

.danger {
  color: $cta-danger;
}

/* Flows */
$clientcredentials: darkgrey;
$ropc: darkseagreen;
$userinfo: darkturquoise;
$apicall: #ff6c37;
$precode: #4e81de;
$vci: #b4288d;
$dcr: #aec32b;
$dcrm: #d6718a;
$refresh: #248d00;
$revoke: #ff4141;
$device: #cf87c9;
$ciba: #bfcf87;
$codeflow: darkcyan;
$implicitflow: salmon;
$tokenexchange: goldenrod;
$hybridflow: mediumvioletred;
$jwt: slateblue;
$createjwt: rebeccapurple;
$logout: #803c15;
$introspectionflow: darkkhaki;

/* -------------------------------------------------------------------
3. Components
------------------------------------------------------------------- */

/* Links */
$link-color: $primary !default;
$link-color-hover: $primary-light !default;

/* Background color */
$background-color: $primary !default;
$background-color-light: $grey-light-30 !default;

/* Background gradients (use with @extend) */

.background-color-gradient {
  background-image: linear-gradient(
    to bottom,
    lighten($primary, 10%),
    $primary
  );
  background-color: $primary;
}

.background-color-gradient-light {
  background-image: linear-gradient(
    to bottom,
    darken($grey-light-30, 5%),
    darken($grey-light-30, 10%)
  );
  background-color: $grey-light-30;
}

/* Navigation */
$navigation-bar-background: $primary-dark;
$navigation-bar-background-active: darken($primary-dark, 4%);

/* Layout */
$header_height: 42px;
$electron_toolbar_height: 41px;
$header_electron_height: 113px;
$tools_form_header_height: 40px;

/* Sidebar / Guide */
$guide-header: 45px;

/* Sidebar / Empty state */
$empty-state-arrow-height: 90px;
