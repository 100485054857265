/* -------------------------------------------------------------------
Alerts
------------------------------------------------------------------- */

.alert {
  position: relative;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  color: $grey;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  @include mq(52em) {
    font-size: 0.85rem;
  }
}

.alert .inline-code {
  margin-inline: .25rem .25rem;
}

.alert p {
  line-height: 1.5;
}

.alert-warning {
  background-color: darken($cta-warning, 10%);
  color: darken($cta-warning, 60%);
  border: 1px solid darken($cta-warning, 20%);

  a,
  p {
    color: darken($cta-warning, 60%);
  }

  .inline-code {
    background-color: darken($cta-warning, 45%);
  }
}

.alert-error,
.alert-danger {
  background-color: lighten($cta-danger, 45%);
  color: darken($cta-danger, 10%);
  border: 1px solid lighten($cta-danger, 35%);

  h4,
  a,
  p {
    color: darken($cta-danger, 10%);
  }

  .inline-code {
    background-color: darken($cta-danger, 50%);
  }
}

.alert-success {
  background-color: lighten($cta-success, 30%);
  color: darken($cta-success, 10%);
  border: 1px solid lighten($cta-success, 20%);

  h4,
  a,
  p {
    color: darken($cta-success, 10%);
  }

  .inline-code {
    background-color: darken($cta-success, 40%);
  }

}

.alert-info {
  background-color: $cta-info;
  color: darken($cta-info, 48%);
  border: 1px solid darken($cta-info, 5%);

  h4,
  a,
  p {
    color: darken($cta-info, 48%);
  }

  .inline-code {
    background-color: darken($cta-info, 40%);
  }
}

/* Variants */

.alert .button-primary {
  color: white;
}

/* Fixed */
.alert-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  margin-top: 0;
  border-radius: 0;
}

/* Snackbar */

.alert-snackbar {
  @extend .alert-fixed;

  width: auto;
  max-width: 500px;
  inset: auto 1rem 1rem auto;
  padding-right: 2rem;
  cursor: pointer;
}

/* Close */

button.alert-close {
  margin-left: auto;
  padding: 0;
  color: inherit;
  background-color: transparent;
  border: 0;
  appearance: none;
  box-shadow: none;

  .icon {
    font-size: 0.85rem;
  }

  &:hover {
    box-shadow: none;
    outline: none;

    .icon {
      color: initial;
    }
  }
}

.alert-close .icon {
  font-size: 1rem;
  cursor: pointer;
}

/* Error labels/text colors */

.is-error.is-error-danger {
  color: $cta-danger;
}

.field-light.is-error.is-error-danger {
  border: 1px solid $cta-danger;
}

.is-error.is-error-warning {
  color: darken($cta-warning, 50%);
}

.field-light.is-error.is-error-warning {
  border: 1px solid darken($cta-warning, 40%);
}

.is-error.is-error-success {
  color: $cta-success;
}

.field-light.is-error.is-error-success {
  border: 1px solid $cta-success;
}

.is-error.is-error-info {
  color: darken($cta-info, 30%);
}

.field-light.is-error.is-error-info {
  border: 1px solid darken($cta-info, 30%);
}

/* -------------------------------------------------------------------
Badges use with the <sup></sup>
------------------------------------------------------------------- */

.badge {
  padding: 3px 6px;
  color: white;
}

.badge-warning {
  background-color: $cta-warning;
}

.badge-danger {
  background-color: $cta-danger;
}

.badge-success {
  background-color: $cta-success;
}

p .badge {
  top: 0;
  margin-left: 4px;
  margin-right: 4px;
}
